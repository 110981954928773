import React from "react";
import Modal from "react-modal";
import classNames from 'classnames'
import "./index.css";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)"
  },
  content: {
    outline: "none"
  }
};

Modal.setAppElement(process.env.NODE_ENV === "test" ? "body" : "#root");

export default ({ contentLabel, showPopup, children, className }) => (
  <Modal
    className={classNames("popup-window", className)}
    contentLabel={contentLabel}
    isOpen={showPopup}
    style={customStyles}
  >
    {children}
  </Modal>
);
