import { compose } from "redux";
import { withRouter } from "react-router";
import withCurrentUser from "lib/containers/withCurrentUser";
import component from "./index.component";
import wrapper from "./index.wrapper";

export default compose(
  withCurrentUser,
  withRouter,
  wrapper
)(component);
