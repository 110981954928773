//
// !!!! Every piece of received Data has a type, and a selector which
//      exactly specifies which data was received.
//
// read more: http://jamesknelson.com/5-types-react-application-state/
//

import { SAVE_DATA, SAVE_VALUE, DROP_DATA, DROP_ALL } from "../actions/constants";

const objectId = object => object.id || object.guid;

const fillObjects = (state = {}, objects) => {
  if (objects.constructor === Array) {
    return objects.reduce(
      (res, object) => {
        res[`id-${objectId(object)}`] = object;
        return res;
      },
      { ...state }
    );
  } else {
    if (objectId(objects)) {
      return { ...state, [`id-${objectId(objects)}`]: objects };
    }
  }
  return state;
};

const fillObjectIds = (state = [], objects) => {
  if (objects.constructor === Array) {
    return objects.map(object => objectId(object));
  } else {
    if (objectId(objects)) {
      return [objectId(objects)];
    }
  }
  return state;
};

const data = (state = {}, action) => {
  switch (action.type) {
    case SAVE_DATA:
      if (action.data !== undefined) {
        return {
          ...state,
          [action.namespace]: fillObjects(state[action.namespace], action.data),
          [`${action.namespace}Ids`]: fillObjectIds(state[`${action.namespace}Ids`], action.data)
        };
      } else {
        return state;
      }
    case SAVE_VALUE:
      if (action.data !== undefined) {
        return { ...state, [action.namespace]: action.data };
      } else {
        return state;
      }
    case DROP_DATA:
      return { ...state, [action.namespace]: undefined, [`${action.namespace}Ids`]: undefined };
    case DROP_ALL:
      return {};
    default:
      return state;
  }
};

export default data;
