import React from "react";
import { Route, Redirect } from "react-router";

export default ({ currentUser, component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      currentUser === undefined ? (
        Layout === undefined ? (
          <Component {...props} />
        ) : (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      ) : (
        <Redirect
          to={{
            pathname: "/dashboard",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
