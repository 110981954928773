//
// !!!! Control State is state which is specific to a given container component,
//      and which is not stored in the screen’s URL or in the HTML5 History API.
//
// read more: http://jamesknelson.com/5-types-react-application-state/
//

import {SET_SECTOR} from "../actions/constants";

const control = (state = {}, action) => {
  switch (action.type) {
    case SET_SECTOR:
      return {...state, sector: action.value}
    default:
      return state;
  }
};

export default control;
