import { connect } from "react-redux";

import { getItems, getSimpleItem, genSaveItemsFunct, genSaveItemFunct } from "lib/stateHelpers";

const mapStateToProps = state => ({
  notifications: getItems(state, "side-nav-notifications"),
  notificationsCount: getSimpleItem(state, "side-nav-notifications-count"),
  notificationsStatus: getSimpleItem(state, "notifications-status")
});

const mapDispatchToProps = dispatch => ({
  saveNotifications: genSaveItemsFunct(dispatch, "side-nav-notifications"),
  saveNotificationsCount: genSaveItemFunct(dispatch, "side-nav-notifications-count"),
  saveNotificationsStatus: genSaveItemFunct(dispatch, "notifications-status")
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
