const fullPath = path => `/complaints/${path}`;

export default notification => {
  const complaintId = notification.complaint && notification.complaint.id;

  if (!complaintId) return;

  switch (notification.category) {
    case "complaint":
    case "task":
    case "task_group":
      if (
        notification.title === "Decision issued" ||
        notification.title === "Decision reminder" ||
        notification.title === "Appeal decision reminder" ||
        notification.title === "Appeal outcome"
      ) {
        return fullPath(`${complaintId}/decisions`);
      } else if (notification.title === "Proposal issued") {
        return fullPath(`${complaintId}/complaint`);
      } else {
        return fullPath(complaintId);
      }
    case "message":
      return fullPath(`${complaintId}/messages`);
    default:
      break;
  }
};
