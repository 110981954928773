import React from "react";

export default Component =>
  class extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isNavOpen: false };
    }

    componentDidMount() {
      document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
    }

    setWrapperRef = node => {
      this.wrapperRef = node;
    };

    handleClickOutside = event => {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        this.navCloseHandler();
      }
    };

    navCloseHandler = () => {
      this.setState({ isNavOpen: false });
    };

    navToggleHandler = () => {
      this.setState({ isNavOpen: !this.state.isNavOpen });
    };

    render = () => (
      <Component
        {...this.props}
        setWrapperRef={this.setWrapperRef}
        navCloseHandler={this.navCloseHandler}
        navToggleHandler={this.navToggleHandler}
        isNavOpen={this.state.isNavOpen}
      />
    );
  };
