import React, {useEffect, useMemo} from "react";
import logoMain from "assets/images/logo-main.svg";
import logoMainSmall from "assets/images/logo-main-small.svg";
import logoEnergy from "assets/images/logo-energy.svg";
import logoEnergySmall from "assets/images/logo-energy-small.svg";
import logoComms from "assets/images/logo-comms.svg";
import logoCommsSmall from "assets/images/logo-comms-small.svg";

const Logo = ({sector}) => {
  const [logo, logoSmall] = useMemo(() => {
    switch (sector) {
      case 'energy':
        return [logoEnergy, logoEnergySmall]
      case 'communications':
        return [logoComms, logoCommsSmall]
      default:
        return [logoMain, logoMainSmall]
    }
  }, [sector])

  return (
    <picture className="logo-main">
      <source srcSet={logoSmall} media="(max-width: 35.5em)"/>
      <img src={logo} alt="Logo"/>
    </picture>
  )
}

export default Logo
