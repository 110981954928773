"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = AuthTokenExpiredException;
require("core-js/modules/es.function.name.js");
function AuthTokenExpiredException(data) {
  this.name = 'AuthTokenExpiredException';
  this.data = data;
  this.message = JSON.stringify(data);
  this.stack = new Error().stack;
}
AuthTokenExpiredException.constructor = AuthTokenExpiredException;