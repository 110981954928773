import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route } from "react-router";
import { ConnectedRouter } from "react-router-redux";

import configureApp from "./configureApp";
import App from "./components/App";
import gtmSetup from "lib/gtm/setup";
import triggerHotjarFeedbackEvent from "lib/gtm/triggerHotjarFeedbackEvent.js";

import "./assets/styles/index.css";

configureApp(({ store, history }) => {
  gtmSetup();
  triggerHotjarFeedbackEvent();

  ReactDOM.render(
    <Provider store={store}>
      {/* ConnectedRouter will use the store from Provider automatically */}
      <ConnectedRouter history={history}>
        <Route component={App} />
      </ConnectedRouter>
    </Provider>,
    document.getElementById("root")
  );
});
