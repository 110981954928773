import callApi from "res-api-cli/build/callApi";

export default ( params = {}, opts = {}) => {
  try {
    return Promise.resolve(callApi({
      ...opts,
      method: 'GET',
      url: "/notifications/menu_list"
    }))
  } catch(e) {
    return Promise.reject(e)
  }
}
