import React from "react";

import { Link } from "react-router-dom";

import UserName from "res-components/build/User/UserName";
import DateFormatter from "res-components/build/DateTime/Formatter";

import SideNavItem from "components/layout/SideNav/Item";

export default ({
  activeTab,
  setTab,
  closeTab,
  complaints,
  navigateToComplaint,
  onSelect,
  count
}) => {
  const name = "your-cases";
  const props = {
    name,
    closeTab,
    openTab: () => setTab(name),
    counter: count,
    isActive: activeTab === name,
    title: "Your cases"
  };

  return (
    <SideNavItem {...props}>
      <ul className="divided-list font-xs">
        {complaints === undefined ? (
          <h2 className="text-center">Loading ...</h2>
        ) : (
          complaints.map(complaint => (
            <li
              test-data={`complaint-${complaint.id}`}
              key={complaint.id}
              data-flex="x-center m-between"
              onClick={() => navigateToComplaint(complaint.id)}
            >
              <div>
                <div test-data="company-name">
                  <strong>{complaint.company.name}</strong>
                </div>
                <div test-data="user-datum">
                  <UserName user={complaint.userDatum} />
                </div>
              </div>
              <div>
                <div test-data="reference">Ref: {complaint.reference}</div>
                <div test-data="created-at">
                  Date: <DateFormatter date={complaint.createdAt} format="weekDayDate" />
                </div>
              </div>
            </li>
          ))
        )}
      </ul>
      {complaints &&
        count > complaints.length && (
          <Link to={"/dashboard?assignedToMe=1"} onClick={closeTab}>
            See more ...
          </Link>
        )}
    </SideNavItem>
  );
};
