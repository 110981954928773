import defaultInputs from "components/Dashboard/SearchDashboard/Complaints/Filter/defaultInputs";
import defaultParams from "res-extensions/build/objectToQueryString";

const link = currentUser => {
  const params = defaultParams(defaultInputs(currentUser.role));
  const link = params => {
    return params.length > 1 ? "/dashboard?" + params : "/dashboard";
  };

  return {
    label: "Your cases",
    link: link(params)
  };
};

export { link as default };
