import React from "react";
import "./index.css";

export default ({ children, className, block, size, type, ...rest }) => {
  const classes = ["btn"];
  const btnType = type && type.includes("submit") ? "submit" : "button";

  if (className) classes.push(className);
  if (block) classes.push("btn-block");
  if (size) classes.push(`btn-${size}`);
  if (type) type.split(" ").map(type => classes.push(`btn-${type}`));

  return (
    <button type={btnType} className={classes.join(" ")} {...rest}>
      <span>{children}</span>
    </button>
  );
};
