import list, { osRoles } from "lib/builders/rolesList";

const isAssignedToMeRole = (role = undefined) =>
  [list.COMPANY_USER, list.OS_ADMIN, list.OS_INVESTIGATIVE_OFFICER, list.OS_DATA_OFFICER].includes(
    role
  );

const isDefaultFilterRole = (role = undefined) => [...osRoles, list.COMPANY_USER].includes(role);

const defaultInputs = (role = undefined) => {
  const params = {};

  if ([list.OS_SENIOR_ADMIN, list.OS_SENIOR_INVESTIGATIVE_OFFICER].includes(role))
    params.withoutUserAssigned = "true";

  if (isAssignedToMeRole(role)) params.assignedToMe = "true";

  if (isDefaultFilterRole(role)) params.requiringAction = "true";

  return params;
};

export { defaultInputs as default, isDefaultFilterRole };
