//
// !!!! Communication state is the status of any not-yet-complete requests to
//      other services.
//
// read more: http://jamesknelson.com/5-types-react-application-state/
//
//  communications store structure:
//  {
//    communication:{
//      signIn: {
//        requestedAt: new Date(),
//        requesting: true,
//        params: { requestParams || {} },
//        errors: { responseErrors || null }
//      }
//    }
//  }

const communications = (state = {}, action) => {
  return state;
};

export default communications;
