"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _constants = require("./constants");
var _default = exports["default"] = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    isNavOpen: false
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _constants.ON_NAV_OPEN:
      return {
        isNavOpen: true
      };
    case _constants.ON_NAV_CLOSE:
      return {
        isNavOpen: false
      };
    default:
      return state;
  }
};