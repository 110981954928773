import React from "react";
import { Link } from "react-router-dom";

export default ({ label, menus, toggle, isOpen, hide }) => (
  <li className="pure-menu-item pure-menu-has-children pure-menu-allow-hover">
    <span className="pure-menu-link" onClick={toggle}>
      {label}
    </span>

    {isOpen && (
      <ul className="pure-menu-children">
        {menus.map((item, index) => (
          <li key={index.toString()} className="pure-menu-item">
            <Link className="pure-menu-link" to={item.link} onClick={hide}>
              {item.label}
            </Link>
          </li>
        ))}
      </ul>
    )}
  </li>
);
