"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.keysToSnakeCase = exports["default"] = void 0;
var _inflected = require("inflected");
var _transformKeys = _interopRequireDefault(require("./transformKeys"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var keysToSnakeCase = exports.keysToSnakeCase = function keysToSnakeCase(object) {
  return (0, _transformKeys["default"])(object, _inflected.underscore);
};
var _default = exports["default"] = keysToSnakeCase;