"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = ForbiddenException;
require("core-js/modules/es.function.name.js");
function ForbiddenException(data) {
  this.name = 'ForbiddenException';
  this.data = data;
  this.message = JSON.stringify(data);
  this.stack = new Error().stack;
}
ForbiddenException.constructor = ForbiddenException;