import React from "react";
import {
  featureIsEnabled,
  getLocalStorageSettingValue,
  pruneLocalStorage,
  setLocalStorageSetting,
  settings, userCanAccessSetting
} from "settings";

export default function wrapper(Component) {
  return class extends React.Component {
    onSubmit = ({ inputs }) => {
      Object.entries(inputs).forEach(([key, value]) => {
        setLocalStorageSetting(key, value);
        window.location.reload();
      });
      pruneLocalStorage();
    };

    getDefaultValues = () => {
      const inputs = {};
      settings.forEach(setting => {
        if (setting.type === "feature") {
          return (inputs[setting.name] = featureIsEnabled(setting.name) ? "true" : "false");
        }
        return getLocalStorageSettingValue(setting.name);
      });
      return inputs;
    };

    render = () => {
      if (!userHasAccess(this.props.currentUser)) {
        return false;
      }
      return <Component {...this.props} onSubmit={this.onSubmit} inputs={this.getDefaultValues()} />
    }
  };
}

function userHasAccess(user) {
  // provide override to switch off settings menu for tests to run in staging environment
  if (localStorage.getItem("settings-ui") !== "true") {
    return false;
  }
  if (window._env_?.REACT_APP_AWS_ENV === "production" || window._env_?.REACT_APP_AWS_ENV ===  "dr") {
    return false;
  }

  const allowedSettings = settings.filter(s => userCanAccessSetting(user, s));
  if (allowedSettings.length === 0) {
    return false
  }


  return (
    window._env_?.REACT_APP_AWS_ENV === "development" || localStorage.getItem("settings-ui") === "true"
  );
}
