import callApi from "res-api-cli/build/callApi";
import { validatorAsync } from "res-api-cli/build/validator";
import { dropNamespace, dropValidationExceptionNamespace } from "res-api-cli/build/dropNamespace";

const schema = {
  guids: { title: "Notification guids", required: true }
};

export default (params, opts = {}) =>
  validatorAsync(schema, params)
    .then(validParams =>
      callApi({
        ...opts,
        method: "PUT",
        url: "/notifications/mark_as_read",
        data: validParams
      }).catch(dropValidationExceptionNamespace("notifications"))
    )
    .then(dropNamespace("notifications"));
