import fetchFeatureFlag from 'fetchers/featureFlags/query';

export default async function getFeatureFlagValue(featureName, fallbackValue) {
  return new Promise((resolve, reject) => {

    // fallbackValue should be used as a defaultValue.
    // In case of any error from the API response, the client will resolve to the fallbackValue.

    // However, if fallbackValue is not specified,
    // then it will reject the promise which then should be handled properly by the caller.
    fetchFeatureFlag({
      featureFlagName: featureName
    })
      .then(response => {
        resolve(response["value"]);
      })
      .catch(error => {
        if (fallbackValue === undefined) {
          reject(error);
        } else {
          resolve(fallbackValue);
        }
      });
  })
}
