import { LOCATION_CHANGE } from "react-router-redux";

export default (state = {}, action) => {
  if (action.type === LOCATION_CHANGE) {
    const newAction = {
      ...action.payload,
      prevPathname: state.pathname
    };

    return newAction;
  }
  return state;
};
