import component from './index.component';
import wrapper from './index.wrapper';
import { compose } from 'redux';

import './index.css';
import withCurrentUser from '../../../lib/containers/withCurrentUser';

export default compose(
  withCurrentUser,
  wrapper)
(component);
