import gtmSettings from "lib/gtm/settings";
import AuthData from "lib/authData";
import { isCompany, isConsumer, isSysAdmin, isOsUser } from "lib/builders/rolesList";

export default () => {
  const gmt = gtmSettings.getGmt();
  if (gmt) {
    const authData = AuthData.get();

    let currentUserType = "undefined";

    if (authData) {
      if (isConsumer(authData.user.role) || isSysAdmin(authData.user.role)) {
        currentUserType = authData.user.role;
      } else if (isOsUser(authData.user.role)) {
        currentUserType = "os";
      } else if (isCompany(authData.user.role)) {
        currentUserType = "company";
      } else {
        currentUserType = "undefined";
      }
    }

    gmt([{ event: "hotjar-feedback", currentUserType }]);
  }
};
