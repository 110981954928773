"use strict";

require("core-js/modules/es.symbol.to-primitive.js");
require("core-js/modules/es.date.to-primitive.js");
require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.object.define-property.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.object.get-own-property-descriptor.js");
require("core-js/modules/es.array.for-each.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.object.get-own-property-descriptors.js");
require("core-js/modules/es.object.define-properties.js");
require("core-js/modules/es.symbol.iterator.js");
require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.promise.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.array.index-of.js");
require("core-js/modules/es.array.concat.js");
require("whatwg-fetch");
var _objectToFormdata = require("object-to-formdata");
var _validation = _interopRequireDefault(require("./exceptions/validation"));
var _unauthorized = _interopRequireDefault(require("./exceptions/unauthorized"));
var _forbidden = _interopRequireDefault(require("./exceptions/forbidden"));
var _unavailable = _interopRequireDefault(require("./exceptions/unavailable"));
var _notFound = _interopRequireDefault(require("./exceptions/notFound"));
var _requestTimeout = _interopRequireDefault(require("./exceptions/requestTimeout/requestTimeout"));
var _authTokenExpired = _interopRequireDefault(require("./exceptions/authTokenExpired"));
var _keysToSnakeCase = _interopRequireDefault(require("res-extensions/build/keysToSnakeCase"));
var _keysToCamelCase = _interopRequireDefault(require("res-extensions/build/keysToCamelCase"));
var _objectToQueryString = _interopRequireDefault(require("res-extensions/build/objectToQueryString"));
var _config = _interopRequireDefault(require("./config"));
var _excluded = ["status"];
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var _default = exports["default"] = function _default() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$fetcher = _ref.fetcher,
    fetcher = _ref$fetcher === void 0 ? window.fetch : _ref$fetcher,
    _ref$apiUrl = _ref.apiUrl,
    apiUrl = _ref$apiUrl === void 0 ? _config["default"].apiUrl : _ref$apiUrl,
    _ref$method = _ref.method,
    method = _ref$method === void 0 ? 'GET' : _ref$method,
    _ref$headers = _ref.headers,
    headers = _ref$headers === void 0 ? {} : _ref$headers,
    _ref$data = _ref.data,
    data = _ref$data === void 0 ? {} : _ref$data,
    _ref$url = _ref.url,
    url = _ref$url === void 0 ? '' : _ref$url,
    _ref$multipart = _ref.multipart,
    multipart = _ref$multipart === void 0 ? false : _ref$multipart;
  data = (0, _keysToSnakeCase["default"])(data);
  headers = _objectSpread(_objectSpread({}, _config["default"].headers), headers);
  var body;
  var queryString = '';
  if (Object.keys(data).length !== 0) {
    if (['GET', 'DELETE'].indexOf(method) > -1) queryString += '?' + (0, _objectToQueryString["default"])(data);else
      // POST or PUT
      if (multipart) {
        delete headers['Content-Type'];
        body = (0, _objectToFormdata.serialize)(data);
      } else {
        body = JSON.stringify(data);
      }
  }
  var fetchResponse = null;
  return fetcher("".concat(apiUrl).concat(url).concat(queryString), {
    method: method,
    headers: headers,
    body: body
  }).then(function (response) {
    fetchResponse = response;
    return response.json().then(function (body) {
      if (!(body !== null && body !== void 0 && body.status)) {
        return _objectSpread({
          status: response.status
        }, body);
      }
      return body;
    })["catch"](function (error) {
      console.error("Error when fetching from ".concat(apiUrl).concat(url).concat(queryString), error);
      return {
        status: response.status
      };
    });
  }).then(function (response) {
    return (0, _keysToCamelCase["default"])(response);
  }).then(function (response) {
    var status = response.status,
      data = _objectWithoutProperties(response, _excluded);
    switch (true) {
      case status === undefined:
        return Promise.reject(response);
      case status >= 200 && status < 300:
        return Promise.resolve(data);
      case status === 401:
        if ((data === null || data === void 0 ? void 0 : data.message) === 'JWT::ExpiredSignature') return Promise.reject(new _authTokenExpired["default"](data));else return Promise.reject(new _unauthorized["default"](data));
      case status === 403:
        return Promise.reject(new _forbidden["default"](data));
      case status === 404:
        return Promise.reject(new _notFound["default"](data));
      case status === 408:
        return (0, _requestTimeout["default"])(response);
      case status === 422:
        return Promise.reject(new _validation["default"](data === null || data === void 0 ? void 0 : data.errors));
      default:
        return Promise.reject(new _unavailable["default"](data));
    }
  })["catch"](function (error) {
    var _fetchResponse;
    var context = {
      requestUrl: url,
      requestMethod: method,
      responseStatus: (_fetchResponse = fetchResponse) === null || _fetchResponse === void 0 ? void 0 : _fetchResponse.status
    };
    return _config["default"].onError(error, context);
  });
};