import component from './index.component'
import {compose} from "redux";
import {withRouter} from "react-router";
import {connect} from "react-redux";

const mapStateToProps = (state) => ({
  sector: state?.control?.sector
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(component);
