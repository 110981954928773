// read before using: http://jamesknelson.com/5-types-react-application-state/

import communication from "./communicationStore";
import data from "./dataStore";
import control from "./controlStore";
import currentLocation from "./currentLocationStore";

import { reducers as mainNavReducers } from "res-components/build/MainNav";

export default {
  communication,
  data,
  control,
  currentLocation,
  mainNav: mainNavReducers
};
