import rolePermissions from './rolePermissions'
import canAccessTasks from 'lib/permissions/accessTasks';
import linkToYourCase from 'lib/builders/menuItems/itemYourCase';
import getHomepageForRole from './getHomepageForRole';
import moment from 'moment';

const navBarMenuList = ({ currentUser }) => {
  const menus = [];
  const createdAtFrom = moment().subtract(6, 'months').format('YYYY-MM-DD');
  const createdAtTo = moment().format('YYYY-MM-DD');

  if (currentUser) {
    const homepage = getHomepageForRole(currentUser.role);
    menus.push({ label: 'Home', link: homepage})

    if (canAccessTasks(currentUser)) menus.push({ label: "Tasks", link: `/tasks?status=new&createdAtFrom=${createdAtFrom}&createdAtTo=${createdAtTo}` });

    menus.push({ label: "Communication preferences", link: "/preferences/communication" });

    if (currentUser.complaintsCount === 1 && currentUser.activeComplaintId) {
      menus[0].link = `/complaints/${currentUser.activeComplaintId}`;
    }

    menus.push(linkToYourCase(currentUser));

    if (rolePermissions[currentUser.role]?.canViewDreDashboards
      && homepage !== '/dashboard/myworkview')
      menus.push({ label: "My work view", link: "/dashboard/myworkview" });

    if (rolePermissions[currentUser.role]?.allowedToCreateOwnNewCase)
      menus.push({ label: "Create a new case", link: "/apply" });

    if (rolePermissions[currentUser.role]?.allowedToSeeOSSignUp)
      menus.push({ label: "Create a new case", link: "/ossignup" });

    if (rolePermissions[currentUser.role]?.canViewAdmin)
      menus.push({ label: "Admin", link: "/admin" });

    menus.push({ label: "Sign Out", link: "/signout" });
  } else {
    menus.push({ label: "Sign Up", link: "/signup" });
    menus.push({ label: "Sign In", link: "/signin" });
  }

  return menus;
};

export default navBarMenuList;
