"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = RequestTimeoutException;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.promise.js");
var _notificationsTimeout = _interopRequireDefault(require("./notificationsTimeout"));
var _unavailable = _interopRequireDefault(require("../../exceptions/unavailable"));
var _completeEnquiryTimeout = _interopRequireDefault(require("./completeEnquiryTimeout"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function RequestTimeoutException(data) {
  switch (data.action) {
    case 'resolve_notifications':
      return Promise.reject(new _notificationsTimeout["default"](data));
    case 'reject_complete_enquiry':
      return Promise.reject(new _completeEnquiryTimeout["default"](data));
    default:
      return Promise.reject(new _unavailable["default"](data));
  }
}