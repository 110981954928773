import callApi from "res-api-cli/build/callApi";
import { validatorAsync } from "res-api-cli/build/validator";

const schema = {
  refreshToken: { title: "refresh token", required: true }
};

export default (params, opts = {}) =>
  validatorAsync(schema, params).then(validParams =>
    callApi({
      ...opts,
      method: "PUT",
      url: "/sessions",
      data: validParams
    })
  );
