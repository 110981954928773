import React from "react";
import complaintsQuery from "fetchers/users/complaints";

export default function wrapper(Component) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {};
      if (props.match && props.match.path !== "/signout") {
        complaintsQuery({ id: props.currentUser.id }).then(({ complaints, count }) => {
          this.setState({ count });
          props.saveComplaints(complaints);
        });
      }
    }

    navigateToComplaint = id => {
      this.props.history.push(`/complaints/${id}`);
      this.props.closeTab();
    };

    render = () => (
      <Component
        {...this.props}
        count={this.state.count}
        navigateToComplaint={this.navigateToComplaint}
      />
    );
  };
}
