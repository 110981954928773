import React from "react";
import { Link } from "react-router-dom";
import Notifications from "components/Notifications";
import SideNavItem from "components/layout/SideNav/Item";

export default ({ tabName,
  openTab,
  onClick,
  closeTab,
  notifications,
  notificationsCount,
  notificationsStatus,
  isActive}) => {

  const sideNavItemProps = {
    closeTab,
    onClick,
    openTab,
    isActive,
    name: tabName,
    counter: notificationsCount > 9 ? <>9{'\u002B'}</> : notificationsCount,
    title: "Your notifications"
  };

  return (
    <SideNavItem {...sideNavItemProps}>
      <Notifications
        notifications={notifications}
        onSelect={onClick}
        status={notificationsStatus}
      />
      <Link to={"/notifications"} onClick={onClick}>
        See more ...
      </Link>
    </SideNavItem>
  );
};
