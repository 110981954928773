import { push as pushRoute } from "react-router-redux";
import { delay } from "redux-saga";
import { take, call, put, fork, cancel } from "redux-saga/effects";

import { SIGNIN, SIGNUP, SIGNIN_ERROR, SIGNOUT, UPDATE_TOKEN } from "actions/constants";
import sessionsUpdate from "fetchers/sessions/update";
import DataStoreActions from "actions/dataStore";

import AuthData from "lib/authData";
import triggerHotjarFeedbackEvent from "lib/gtm/triggerHotjarFeedbackEvent.js";

// updates authToken every hour
function* updateToken() {
  yield delay(3600000); // 1 hour

  const { refreshToken } = AuthData.get();

  const result = yield call(sessionsUpdate, { refreshToken });

  AuthData.store(result);

  yield call(updateToken);
}

function* loginFlow() {
  let updateTokenTask = null;
  while (true) {
    // wait of any of this task
    const action = yield take([SIGNIN, SIGNUP, SIGNOUT, SIGNIN_ERROR, UPDATE_TOKEN]);

    switch (action.type) {
      case SIGNUP:
      case SIGNIN:
        // keep task to cancel it if there is SIGNOUT dispached
        updateTokenTask = yield fork(updateToken);

        triggerHotjarFeedbackEvent();

        break;
      case SIGNOUT:
        if (updateTokenTask) {
          yield cancel(updateTokenTask);
          // clear it, task is canceled
          updateTokenTask = null;
        }

        AuthData.drop();

        triggerHotjarFeedbackEvent();

        yield put(DataStoreActions.dropAll());
        yield put(pushRoute("/"));

        break;
      case UPDATE_TOKEN:
        updateTokenTask = yield fork(updateToken);

        break;
      default:
        break;
    }
  }
}

export default loginFlow;
