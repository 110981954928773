"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _Toggle = _interopRequireDefault(require("./Toggle.component"));
var _container = _interopRequireDefault(require("../container"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var _default = exports["default"] = (0, _container["default"])(_Toggle["default"]);