import React from "react";
import SomethingWentWrong from "components/error/SomethingWentWrong";

export default class extends React.Component {
  state = { error: undefined };

  static getDerivedStateFromProps = (props, state) => ({ error: undefined });

  componentDidCatch = (error, info) => this.setState({ error: error.message });

  render = () => (this.state.error ? <SomethingWentWrong /> : this.props.children);
}
