import React from "react";
import Modal from "./Modal";

const initials = user => {
  const { firstName, lastName } = user || {};

  if (firstName && lastName) return `${firstName.charAt(0)} ${lastName.charAt(0)}`;

  return "AA";
};

export default ({
  currentUser,
  navCloseHandler,
  navToggleHandler,
  isNavOpen,
  complaints,
  setWrapperRef
}) => (
  <div className="profile-tab" ref={setWrapperRef}>
    <div className="profile-initials" onClick={navToggleHandler}>
      {initials(currentUser)}
    </div>
    {isNavOpen && (
      <Modal currentUser={currentUser} navCloseHandler={navCloseHandler} complaints={complaints} />
    )}
  </div>
);
