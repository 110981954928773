import React from "react";
import "./index.css";

export default () => (
  <main
    className="error-page container-fluid text-center width-100"
    data-flex="column m-center x-center"
  >
    <h1 className="error-numbered">500</h1>
    <p>Sorry, something went wrong</p>
    <a href="/">Take me back please</a>
  </main>
);
