import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  typography: {
    fontFamily: "inherit"
  },
  palette: {
    primary: {
      main: "#000"
    },
    secondary: {
      main: "#000"
    }
  }
});

export default theme;
