export const SIGNIN = "SIGNIN";
export const SIGNUP = "SIGNUP";
export const SIGNIN_ERROR = "SIGNIN_ERROR";
export const SIGNOUT = "SIGNOUT";
export const UPDATE_TOKEN = "UPDATE_TOKEN";

export const SAVE_DATA = "SAVE_DATA";
export const SAVE_VALUE = "SAVE_VALUE";

export const DROP_DATA = "DROP_DATA";
export const DROP_ALL = "DROP_ALL";

export const SET_SECTOR = "SET_SECTOR"
