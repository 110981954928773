import React, {useEffect} from "react";
import {Route, Switch} from "react-router";
import {Toggle} from "res-components/build/MainNav";
import Navbar from "./Navbar";
import Logo from "./Logo";
import "./index.css";
import {setBodyClass, updateDocumentBranding} from "../../../lib/branding";

const Header = ({sector}) => {
  useEffect(() => {
    setBodyClass(sector)
    updateDocumentBranding(sector)

    return () => {
      setBodyClass("")
      updateDocumentBranding("")
    }
  }, [sector]);

  return (
    <div id="header" className="container-fluid clearfix">
      <a href="/">
        <Logo />
      </a>
      <Switch>
        <Route path="/dashboard" exact component={null} />
        <Route path="/dashboard/*" exact component={null} />
        <Route path="/signin" exact component={null} />
        <Route path="/signup" exact component={null} />
        <Route path="/signup-energy" exact component={null} />
        <Route path="/signup-comms" exact component={null} />
        <Route
          path="*"
          render={() => (
            <div>
              <Toggle />
              <Navbar />
            </div>
          )}
        />
      </Switch>
    </div>
  );
}

export default Header
