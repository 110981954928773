import { LOCATION_CHANGE } from "react-router-redux";
import AuthData from "lib/authData";

const emitPageView = action => {
  const res = {
    hitType: "pageview",
    page: action.payload
  };

  const authData = AuthData.get();

  if (authData) {
    res.currentUserId = authData.user.id;
    res.currentUserRole = authData.user.role;
  } else {
    res.currentUserId = undefined;
    res.currentUserRole = undefined;
  }

  return res;
};

const eventsMap = {
  [LOCATION_CHANGE]: emitPageView
};

export default eventsMap;
