import React from "react";
import { NavLink } from "react-router-dom";
import ErrorBoundary from "components/ErrorBoundary";
import NotificationsTab from "./tabs/Notifications";
import ComplaintsTab from "./tabs/Complaints";
import Profile from "./tabs/Profile";
import RebrandPopup from "components/RebrandPopup";
import MaintenancePopup from "components/MaintenancePopup";

export default ({ setTab, closeTab, activeTab, children, setWrapperRef, homepage }) => (
  <div className="page-side-nav">
    <div className="side-nav" ref={setWrapperRef}>
      <Profile />
      <NotificationsTab activeTab={activeTab} setTab={setTab} closeTab={closeTab} />
      <NavLink
        className="side-nav-item dashboard"
        activeClassName="active"
        to={homepage}
        onClick={closeTab}
      />
      <ComplaintsTab activeTab={activeTab} setTab={setTab} closeTab={closeTab} />
    </div>
    <ErrorBoundary>
      <div className="content-area">{children}</div>
    </ErrorBoundary>
    <RebrandPopup />
    <MaintenancePopup />
  </div>
);
