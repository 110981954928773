"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.promise.js");
var config = {
  apiUrl: '',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  onError: function onError(e) {
    return Promise.reject(e);
  }
};
var _default = exports["default"] = config;