import React, { useEffect, useState } from 'react';
import getFeatureFlagValue from 'lib/featureFlagClient';
import { getEnvBool } from 'lib/helpers/env';
import LoadingSVG from 'components/common/LoadingSVG';

export const NEXT_ACTION_LOADING_UI = 'next_action_loading_ui'
export const B2B_REMIT_EXTENSION = 'b2b_remit_extension_enabled'
export const SUBMISSION_ADDITIONAL_SUPPORT = 'submission_flow_additional_support_enabled'

export const defaultFeatures = {
  [NEXT_ACTION_LOADING_UI]:        false,
  [B2B_REMIT_EXTENSION]:           getEnvBool('B2B_REMIT_EXTENSION_ENABLED', false),
  [SUBMISSION_ADDITIONAL_SUPPORT]: false,
}

export async function getFeatureValues() {
  const features = { ...defaultFeatures };

  const requests = Object.entries(defaultFeatures).map(([feature, defaultValue]) => {
    return new Promise((resolve) => {
      getFeatureFlagValue(feature, defaultValue)
        .then(value => {
          resolve({
            [feature]: value
          })
        })
    })
  })

  const featuresArray = await Promise.all(requests)
  for (const obj of featuresArray) {
    Object.assign(features, obj);
  }

  return features;
}

// Hold features in memory
let featureStore = {}

export const FeatureProvider = ({ children }) => {
  const [featuresLoaded, setFeaturesLoaded] = useState(false);
  const authToken = localStorage.getItem('oso-auth');
  const timeoutInMinutes = 10;
  const timeoutToken = Math.floor(new Date().getTime() / (1000 * 60 * timeoutInMinutes))

  useEffect(() => {
    if (authToken) {
      getFeatureValues()
        .then(features => {
          featureStore = features;
        })
        .finally(() => {
          setFeaturesLoaded(true);
        })
    } else {
      featureStore = {}
      setFeaturesLoaded(false);
    }
  }, [authToken, timeoutToken])

  if (!authToken) {
    return children;
  }

  if (!featuresLoaded) {
    return (
      <div className="container mt-10">
        <LoadingSVG size={32} delay={500} />
      </div>
    );
  }

  return children
}

// Features hook, can be used in functional components
export function getFeature(featureName) {
  return featureStore[featureName];
}

// To be used in tests to mock the value of a feature
export function mockFeature(featureName, value) {
  featureStore[featureName] = value
}
