import { compose } from "redux";
import inputState from "res-components/build/lib/wrappers/inputState";
import withCurrentUser from "lib/containers/withCurrentUser";
import wrapper from "./index.wrapper";
import component from "./index.component";

export default compose(
  withCurrentUser,
  wrapper,
  inputState
)(component);
