import DataStoreActions from "actions/dataStore";

export const getItem = (state, namespace, id) =>
  state.data[namespace] && state.data[namespace][`id-${id}`];

export const getSimpleItem = (state, namespace) => state.data[namespace];

export const getItems = (state, namespace) =>
  state.data[`${namespace}Ids`] &&
  state.data[`${namespace}Ids`].map(id => getItem(state, namespace, id));

export const genSaveItemsFunct = (dispatch, namespace) => items => {
  dispatch(DataStoreActions.saveData(namespace, items));

  return items;
};

export const genSaveItemFunct = (dispatch, namespace) => item => {
  dispatch(DataStoreActions.saveValue(namespace, item));

  return item;
};

export const genDropItemsFunct = (dispatch, namespace) => () =>
  dispatch(DataStoreActions.dropData(namespace));

export default { getItem, getItems };
