import React from "react";
import path from "./path";

export default Component =>
  class extends React.Component {
    onSelect = () => {
      const fullPath = path(this.props.notification);

      if (!fullPath) return;

      this.props.history.push(fullPath);

      if (this.props.onSelect) this.props.onSelect(this.props.notification);
    };

    render = () => <Component {...this.props} onSelect={this.onSelect} />;
  };
