import React from "react";
import { Nav } from "res-components/build/MainNav";
import ErrorBoundary from "components/ErrorBoundary";
import Header from "./Header/index";
import MobileNavbar from "./Header/MobileNavbar";
import RebrandPopup from "components/RebrandPopup";
import MaintenancePopup from "components/MaintenancePopup";

export default ({ children }) => (
  <div>
    <Nav renderLinks={() => <MobileNavbar />} />
    <Header />
    <ErrorBoundary>{children}</ErrorBoundary>
    <RebrandPopup />
    <MaintenancePopup />
  </div>
);
