import { compose } from "redux";
import { withRouter } from "react-router";

import component from "./index.component";
import container from "./index.container";
import wrapper from "./index.wrapper";
import withCurrentUser from 'lib/containers/withCurrentUser';

export default compose(
  withRouter,
  withCurrentUser,
  container,
  wrapper
)(component);
