import apiConfig from "res-api-cli/build/config";

const tokenVersion = 2;
const storeKey = "oso-auth";
const errorMessage =
  "No access to localStorage. Please update or change security settings of your browser";

const AuthData = {
  store: authData => {
    apiConfig.headers.Authorization = authData.token;
    apiConfig.authRefreshToken = authData.refreshToken;

    if (authData === undefined) console.error("There is no auth data provided");
    if (window === undefined || window.localStorage === undefined) throw errorMessage;

    authData.tokenVersion = tokenVersion;
    window.localStorage.setItem(storeKey, JSON.stringify(authData));
  },
  get: () => {
    if (window === undefined || window.localStorage === undefined) throw errorMessage;

    const authData = window.localStorage.getItem(storeKey);
    if (!authData || authData === "undefined") return;

    const parsed = JSON.parse(authData);
    if (parsed.tokenVersion === tokenVersion) return parsed;
  },
  drop: () => {
    delete apiConfig.headers.Authorization;
    delete apiConfig.authRefreshToken;

    if (window === undefined || window.localStorage === undefined) throw errorMessage;

    window.localStorage.removeItem(storeKey);
  }
};

export default AuthData;
