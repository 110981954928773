import { connect } from "react-redux";
import component from './index.component'

const mapStateToProps = (state) => {
  return ({
    sector: state?.control?.sector
  });
}

export default connect(
  mapStateToProps
)(component);
