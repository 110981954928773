import callApi from "res-api-cli/build/callApi";
import { validatorAsync } from "res-api-cli/build/validator";
import { dropNamespace } from "res-api-cli/build/dropNamespace";

const schema = {
  id: { title: "User Id", required: true, numeric: true }
};

export default (params = {}, opts = {}) =>
  validatorAsync(schema, params).then(validParams =>
    callApi({
      ...opts,
      method: "GET",
      url: `/users/${params.id}/complaints`,
      data: {}
    }).then(dropNamespace("complaints", "count"))
  );
