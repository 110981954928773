import React from "react";

import Icon from "components/common/Icon";

export default ({ isActive, openTab, closeTab, name, title, counter, children }) => {
  const counterEl = counter ? <span className="counter counter-small">{counter}</span> : "";

  return (
    <div>
      <div
        aria-label={title}
        data-flex="x-center m-around"
        onClick={openTab}
        className={`side-nav-item ${name} ${isActive && "active"}`}
      >
        {counterEl}
      </div>
      {isActive && (
        <div className="side-nav-item-content">
          <div className="container-fluid">
            <strong className="block font-lg mb-6 clearfix">
              {title}
              <Icon type="close" className="pull-right pointer" onClick={closeTab} />
            </strong>
            {children}
          </div>
        </div>
      )}
    </div>
  );
};
