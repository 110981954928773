import React from "react";
import { Route } from "react-router";

export default ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      Layout === undefined ? (
        <Component {...props} />
      ) : (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }
  />
);
