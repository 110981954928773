import Loadable from "react-loadable";

import Loading from "components/Loading";

const opts = {
  loading: Loading,
  delay: 200,
  timeout: 30000
};

export default importComponent => Loadable({ ...opts, loader: importComponent });
