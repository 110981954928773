import React from "react";
import getHomepageForRole from '../../../lib/builders/getHomepageForRole';

export default function wrapper(Component) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = { activeTab: undefined };
    }

    setTab = tabName => {
      if (this.state.activeTab === tabName) {
        this.closeTab();
      } else {
        this.setState({ activeTab: tabName });
      }
    };

    closeTab = () => {
      this.setState({ activeTab: undefined });
    };

    componentDidMount() {
      document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
    }

    setWrapperRef = node => {
      this.wrapperRef = node;
    };

    handleClickOutside = event => {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        this.closeTab();
      }
    };

    render = () => (
      <Component
        {...this.props}
        setWrapperRef={this.setWrapperRef}
        setTab={this.setTab}
        closeTab={this.closeTab}
        activeTab={this.state.activeTab}
        homepage={getHomepageForRole(this.props.currentUser?.role)}
      />
    );
  };
}
