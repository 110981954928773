"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.object.to-string.js");
var _default = exports["default"] = function _default() {
  var user = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return [user.title, user.firstName, user.lastName].filter(function (attr) {
    return attr;
  }).join(' ');
};