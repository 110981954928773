import React from "react";
import ReloadPagePopup from "components/common/ReloadPagePopup";

export default ({ isLoading, timedOut, pastDelay, error }) => {
  if (isLoading) {
    // While our other component is loading...
    if (timedOut) {
      // In case we've timed out loading our other component.
      return <div>Loader timed out!</div>;
    } else if (pastDelay) {
      // Display a loading screen after a set delay.
      return <div>Loading...</div>;
    } else {
      // Don't flash "Loading..." when we don't need to.
      return null;
    }
  } else if (error) {
    console.log(error);
    // If we aren't loading, maybe
    if (error.message.match(/Loading chunk(.)* failed/)) {
      return <ReloadPagePopup />;
    } else {
      return <div>Error! Component failed to load</div>;
    }
  } else {
    // This case shouldn't happen... but we'll return null anyways.
    return null;
  }
};
