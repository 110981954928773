import {validatorAsync} from "res-api-cli/build/validator";
import apiConfig from 'res-api-cli/build/config';
import Honeybadger from "honeybadger-js";
import {getLocalStorageSettingValue} from "settings";

const schema = {
  featureFlagName: {title: "Feature Flag Name", required: true}
};

export default (params = {}, opts = {}) =>
  validatorAsync(schema, params).then(validParams => {
    return new Promise((resolve, reject) => {

      let featureUrl = apiConfig.apiUrl;

      const urlOverride = getLocalStorageSettingValue("feature-url");
      if (urlOverride) {
        featureUrl = urlOverride;
      }

      const finalUrl = `${featureUrl}/cms/feature/flag/${validParams.featureFlagName}`;

      fetch(finalUrl, {
        method: 'GET',
        headers: apiConfig.headers
      }).then(response => {
        if (response.status >= 200 && response.status < 300) {
          resolve(response.json());
        } else {
          Honeybadger.notify(response.statusText, 'Error in API - ' + finalUrl, {
            context: {
              error: `Failed to fetch feature flag ${validParams.featureFlagName}`
            }
          });
          reject(response);
        }
      }).catch(err => {
        Honeybadger.notify(err.message, 'Error in API - ' + finalUrl, {
          context: {
            error: `Failed to fetch feature flag ${validParams.featureFlagName}`
          }
        });
        reject(err);
      });
    })
  });
