import React from "react";
import { Route, Redirect } from "react-router";
import can from "lib/permissions/can";

export default ({
  currentUser,
  permission,
  redirectTo,
  component: Component,
  layout: Layout,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      currentUser === undefined ? (
        <Redirect
          to={{
            pathname: redirectTo || "/signin",
            search: `?url=${encodeURIComponent(
              `${props.location.pathname}${props.location.search}`
            )}`,
            state: { from: props.location }
          }}
        />
      ) : permission === undefined || can(currentUser)(permission) ? (
        Layout === undefined ? (
          <Component {...props} />
        ) : (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      ) : (
        <Redirect to={{ pathname: "/not-found" }} />
      )
    }
  />
);
