import React from "react";
import { Link } from "react-router-dom";

import { MenuList, MenuLink } from "res-components/build/MainNav";
import menuBuilder from "lib/builders/navBarMenuList";

export default ({ currentUser }) => (
  <MenuList>
    {menuBuilder({ currentUser }).map((menu, index) => (
      <MenuLink key={index.toString()} to={menu.link} linkComponent={Link}>
        {menu.label}
      </MenuLink>
    ))}
  </MenuList>
);
