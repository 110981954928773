import { createStore, combineReducers, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import { routerReducer, routerMiddleware } from "react-router-redux";
import createSagaMiddleware from "redux-saga";
import createHistory from "history/createBrowserHistory";

import AuthData from "lib/authData";
import sessionsUpdate from "fetchers/sessions/update";
import AuthenticationActions from "actions/communication/authentication";
import DataStoreActions from "actions/dataStore";
import configureApi from "configureApi";
import configureHoneybadger from "configureHoneybadger";
import appReducers from "reducers";
import sagas from "sagas";
import gtm from "lib/gtm/settings";

const updateAuthData = store => {
  const authData = AuthData.get();

  if (authData && authData.refreshToken) {
    AuthData.store(authData); // updates api request headers

    return sessionsUpdate({ refreshToken: authData.refreshToken })
      .then(result => {
        AuthData.store(result);

        store.dispatch(AuthenticationActions.updateToken());
      })
      .catch(e => {
        AuthData.drop();

        store.dispatch(DataStoreActions.dropAll());
      });
  } else {
    return Promise.resolve();
  }
};

export default callback => {
  const history = createHistory();

  configureApi({ history });
  configureHoneybadger();

  const sagaMiddleware = createSagaMiddleware();
  const gtmMiddleware = gtm.getMiddleware();
  const middlewares = [
    sagaMiddleware,
    routerMiddleware(history),
    ...(gtmMiddleware ? [gtmMiddleware] : [])
  ];

  if (process.env.NODE_ENV !== "production" && localStorage.getItem('redux-logger') !== 'false') {
    middlewares.push(createLogger());
  }

  const gtmReducers = gtm.getReducers();
  const reducers = { ...appReducers, router: routerReducer, ...(gtmReducers || {}) };
  const store = createStore(combineReducers(reducers), applyMiddleware(...middlewares));

  sagaMiddleware.run(sagas);

  updateAuthData(store).then(() => callback({ store, history }));
};
