import { SAVE_DATA, SAVE_VALUE, DROP_DATA, DROP_ALL } from "./constants";

export default {
  saveData: (namespace, data) => {
    return { type: SAVE_DATA, namespace, data };
  },
  saveValue: (namespace, data) => {
    return { type: SAVE_VALUE, namespace, data };
  },
  dropData: namespace => {
    return { type: DROP_DATA, namespace };
  },
  dropAll: () => {
    return { type: DROP_ALL };
  }
};
