import React from "react";
import "./index.css";

export default ({ className, type, ...rest }) => {
  const classes = ["icon"];

  if (className) className.split(" ").map(className => classes.push(className));
  if (type) type.split(" ").map(type => classes.push(`icon-${type}`));

  return <span className={classes.join(" ")} {...rest} />;
};
