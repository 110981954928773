"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validatorAsync = exports.validateObject = exports["default"] = void 0;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.string.match.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.promise.js");
var _approvejs = _interopRequireDefault(require("approvejs"));
var _moment = _interopRequireDefault(require("moment"));
var _lodash = _interopRequireDefault(require("lodash"));
var _isArray = _interopRequireDefault(require("lodash/isArray"));
var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));
var _forEach = _interopRequireDefault(require("lodash/forEach"));
var _validation = _interopRequireDefault(require("./exceptions/validation"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var customValidators = [{
  "boolean": {
    message: '{title} must be boolean',
    validate: function validate(value, pars) {
      if (value === undefined || value === null) return !(pars.required === true);
      return typeof value === 'boolean';
    }
  }
}, {
  dateAge: {
    expects: ['ago', 'period'],
    message: 'Date cannot be less than {ago} {period} old',
    validate: function validate(value, pars) {
      if (!value) return true;
      var momentValue = (0, _moment["default"])(value);
      return momentValue.isValid() && momentValue < (0, _moment["default"])().subtract(pars.ago, pars.period).utc();
    }
  }
}, {
  filled: {
    message: '{title} is required',
    validate: function validate(value) {
      if (!value || (0, _isArray["default"])(value) && (0, _isEmpty["default"])(value)) return false;
      return true;
    }
  }
}, {
  notFilled: {
    message: '{title} is prohibited',
    validate: function validate(value) {
      return !value || (0, _isArray["default"])(value) && (0, _isEmpty["default"])(value);
    }
  }
}, {
  numericOrNil: {
    message: "{title} may only contain [0-9] numbers or 'nil' string",
    validate: function validate(value) {
      var regex = /^-?[0-9]+$/;
      if (!value || !(regex.test(value) || value === 'nil')) return false;
      return true;
    }
  }
}, {
  oneOf: {
    message: "{title} is invalid",
    expects: ['contains'],
    validate: function validate(value, pars) {
      var title = pars.title,
        contains = pars.contains;
      var result = {
        valid: false,
        errors: []
      };
      if (value && contains.includes(value)) {
        result.valid = true;
      } else {
        var message = "{title} must be one of: ".concat(contains.join(', '));
        result.valid = false;
        result.errors.push(message);
      }
      return result;
    }
  }
}, {
  strengthOwaspSpecial: {
    message: '{title} is not strong enough',
    expects: ['min'],
    validate: function validate(value, pars) {
      var minimum = pars.min || 8;
      var strengths = {
        0: 'Very Weak',
        1: 'Weak',
        2: 'Better',
        3: 'Almost',
        4: 'Acceptable',
        5: 'Strong',
        6: 'Very Strong'
      };
      var errors = {
        isMinimum: '{title} must be at least {min} characters',
        hasLower: '{title} must have at least 1 lower case character',
        hasUpper: '{title} must have at least 1 upper case character',
        hasNumber: '{title} must have at least 1 number',
        hasSpecial: '{title} must have at least 1 special character'
      };
      var _getScore = function _getScore(text) {
        var specials = / |£|!|"|#|\$|%|&|'|\(|\)|\*|\+|,|-|\.|\/|:|;|<|=|>|\?|@|\[|\\|\]|\^|_|`|{|\||}|~/;
        var result = {
          strength: strengths[0],
          points: 0,
          isMinimum: false,
          hasLower: false,
          hasUpper: false,
          hasNumber: false,
          hasSpecial: false,
          percent: 0,
          valid: false,
          errors: []
        };
        result.isMinimum = text.length >= minimum;
        if (result.isMinimum) result.points++;
        result.hasLower = text.match(/[a-z]/) === null ? false : true;
        if (result.hasLower) result.points++;
        result.hasUpper = text.match(/[A-Z]/) === null ? false : true;
        if (result.hasUpper) result.points++;
        result.hasNumber = text.match(/\d+/) === null ? false : true;
        if (result.hasNumber) result.points++;
        result.hasSpecial = text.match(specials) === null ? false : true;
        if (result.hasSpecial) result.points++;
        result.percent = Math.ceil(result.points / 6 * 100);
        return result;
      };
      var _getStrength = function _getStrength(text) {
        var result = _getScore(text);
        result.strength = strengths[result.points];
        if (!result.isMinimum) result.errors.push(errors.isMinimum);
        if (!result.hasLower) result.errors.push(errors.hasLower);
        if (!result.hasUpper) result.errors.push(errors.hasUpper);
        if (!result.hasSpecial) result.errors.push(errors.hasSpecial);
        if (!result.hasNumber) result.errors.push(errors.hasNumber);
        if (result.points > 4) result.valid = true;
        return result;
      };
      if (pars.config && pars.config.messages) {
        (0, _forEach["default"])(pars.config.messages, function (message) {
          return errors[message] = pars.config.messages[message];
        });
      }
      return _getStrength(value);
    }
  }
}];
customValidators.map(function (validator) {
  var validatorName = Object.keys(validator)[0];
  _approvejs["default"].addTest(validator[validatorName], validatorName);
});
var validateObject = exports.validateObject = function validateObject(schema, object) {
  var isValid = true;
  var errors = {};
  var params = {};
  for (var key in schema) {
    if (_lodash["default"].has(schema, key)) {
      var result = _approvejs["default"].value(_lodash["default"].get(object, key), _lodash["default"].get(schema, key));
      if (!result.approved) {
        isValid = false;
        _lodash["default"].set(errors, key, result.errors.filter(function (value) {
          return value !== undefined && value !== null && value !== "";
        }));
      } else if (_lodash["default"].has(object, key)) {
        _lodash["default"].set(params, key, _lodash["default"].get(object, key));
      }
    }
  }
  return {
    isValid: isValid,
    errors: errors,
    params: params
  };
};
var validator = exports["default"] = function validator(schema, object) {
  var result = validateObject(schema, object);
  if (result.isValid) {
    return result.params;
  } else {
    throw new _validation["default"](result.errors);
  }
};
var validatorAsync = exports.validatorAsync = function validatorAsync(schema, object) {
  try {
    return Promise.resolve(validator(schema, object));
  } catch (e) {
    return Promise.reject(e);
  }
};