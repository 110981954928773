import React from "react";
import { Link } from "react-router-dom";
import menuBuilder from "lib/builders/navBarMenuList";
import DropDown from "components/layout/Header/DropDown";

export default ({ currentUser }) => {
  const menus = menuBuilder({ currentUser });

  return (
    <div className="pure-menu pure-menu-horizontal visible-lg">
      <ul className="pure-menu-list pull-right">
        {currentUser ? (
          <DropDown label={`Signed in as ${currentUser.email}`} menus={menus} />
        ) : (
          menus.map((menu, index) => (
            <li key={index.toString()} className="pure-menu-item">
              <Link to={menu.link} className="pure-menu-link">
                {menu.label}
              </Link>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};
