export function setBodyClass(sector) {
  const b = document.body;
  b.classList.forEach(c => {
    if (c.startsWith('sector-')) {
      b.classList.remove(c)
    }
  })
  if (sector) {
    b.classList.add(`sector-${sector}`)
  }
}

export function updateDocumentBranding(sector) {
  switch (sector) {
    case "energy":
      setFavicon("favicon-energy.png");
      break;
    case "communications":
      setFavicon("favicon-comms.png");
      break;
    default:
      setFavicon("favicon.png");
  }
}

export function setFavicon(image) {
  const link = document.querySelector('link[rel~="icon"]');
  if (!link) {
    return;
  }
  link.href = link.href.replace(/[\w-_]+\.png$/, image || "favicon.png");
}

export function setTitle(text) {
  return (document.title = text);
}

export function resetTitle() {
  return (document.title = "Dispute Resolution Platform");
}