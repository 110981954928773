import React from "react";

export default function wrapper(Component) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = { menuOpen: false };
    }

    hide = e => this.setState({ menuOpen: false });
    show = e => this.setState({ menuOpen: true });
    toggle = e => (this.state.menuOpen ? this.hide(e) : this.show(e));

    render = () => (
      <Component
        {...this.props}
        isOpen={this.state.menuOpen}
        show={this.show}
        hide={this.hide}
        toggle={this.toggle}
      />
    );
  };
}
