"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.keysToCamelCase = exports["default"] = void 0;
var _camelCase = _interopRequireDefault(require("lodash/camelCase"));
var _transformKeys = _interopRequireDefault(require("./transformKeys"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var keysToCamelCase = exports.keysToCamelCase = function keysToCamelCase(object) {
  return (0, _transformKeys["default"])(object, _camelCase["default"]);
};
var _default = exports["default"] = keysToCamelCase;