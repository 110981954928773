"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.removeNamespace = exports["default"] = void 0;
var removeNamespace = exports.removeNamespace = function removeNamespace(object, namespace) {
  return namespace && object && object[namespace];
};
var _default = exports["default"] = removeNamespace;