"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = NotificationsTimeout;
require("core-js/modules/es.function.name.js");
function NotificationsTimeout(data) {
  this.name = 'BunnyPublisherTimeoutException';
  this.notifications = data.notifications;
  this.count = data.count;
  this.status = 408;
}
NotificationsTimeout.constructor = NotificationsTimeout;