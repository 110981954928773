import { SIGNIN, SIGNUP, SIGNOUT, SIGNIN_ERROR, UPDATE_TOKEN } from "../constants";

export default {
  signIn: () => {
    return { type: SIGNIN };
  },
  signUp: () => {
    return { type: SIGNUP };
  },
  signInError: () => {
    return { type: SIGNIN_ERROR };
  },
  signOut: params => {
    return { type: SIGNOUT };
  },
  updateToken: () => {
    return { type: UPDATE_TOKEN };
  }
};
