import { createMiddleware as createBeaconMiddleware, createMetaReducer } from "redux-beacon";
import GoogleTagManager from "@redux-beacon/google-tag-manager";
import apiConfig from "res-api-cli/build/config";

import eventsMap from "./eventsMap";

let gtm = undefined;
let middleware = undefined;
let reducers = undefined;

const getGmt = () => {
  if (!gtm && apiConfig.gtmId) gtm = GoogleTagManager();

  return gtm;
};

const getMiddleware = () => {
  if (apiConfig.gtmId && middleware === undefined)
    middleware = createBeaconMiddleware(eventsMap, getGmt());

  return middleware;
};

const getReducers = () => {
  if (apiConfig.gtmId && reducers === undefined) reducers = createMetaReducer(eventsMap, getGmt());

  return reducers;
};

export default { getGmt, getMiddleware, getReducers };
