import React from "react";
import { Link } from "react-router-dom";
import menuBuilder from "lib/builders/navBarMenuList";
import Icon from "components/common/Icon";
import UserName from "res-components/build/User/UserName";

export default ({ currentUser, navCloseHandler, complaints }) => {
  const menus = menuBuilder({ currentUser, complaints });
  const role = currentUser ? `${currentUser.role}` : "";
  const menu = (
    <ul>
      {menus.map((item, index) => (
        <li key={index.toString()}>
          <Link to={item.link} onClick={navCloseHandler}>
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  );

  return (
    <div className="profile-modal has-shadow">
      <Icon className="profile-modal-close pointer" type="close small" onClick={navCloseHandler} />
      <h5 className="mt-2 mb-0">
        <UserName user={currentUser} />
      </h5>
      <span className="font-sm">{role}</span>
      {menu}
    </div>
  );
};
