import React from "react";
import nameFormatter from "lib/users/nameFormatter";
import FromNow from "res-components/build/DateTime/FromNow";

export default ({ onSelect, notification, currentUser }) => (
  <div onClick={onSelect}>
    {notification.complaint === undefined ? (
      <div>{nameFormatter(currentUser)}</div>
    ) : (
      <div>
        <strong test-data="complaint-reference" className="block">
          {notification.complaint.reference}
        </strong>
        <div test-data="complaint-user-datum">
          {notification.complaint.userDatum
            ? [
                nameFormatter(notification.complaint.userDatum),
                "-",
                notification.complaint.company.name
              ].join(" ")
            : notification.complaint.company.name}
        </div>
      </div>
    )}
    <div data-flex="m-between">
      <span test-data="notification-title">{notification.title}</span>
      <FromNow date={notification.createdAt} />
    </div>
  </div>
);
