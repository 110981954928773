import React from "react";
import AuthData from "lib/authData";

export default Component =>
  class extends React.Component {
    render = () => {
      const authData = AuthData.get();

      return <Component {...this.props} currentUser={authData && authData.user} />;
    };
  };
