import { rolesList } from './rolesList';

const defaultHomepage = '/dashboard';
const roleHomepages = {
  [rolesList.DRE_ONE]:   '/dashboard/myworkview',
  [rolesList.DRE_TWO]:   '/dashboard/myworkview',
  [rolesList.DRE_THREE]: '/dashboard/myworkview',
  [rolesList.COACH]:     '/dashboard/coach',
  [rolesList.LEADER]:    '/dashboard/leader',
}

/**
 * Get the URL of the homepage for the given role.
 */
const getHomepageForRole = role => roleHomepages[role] ?? defaultHomepage;
export default getHomepageForRole;
