"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _constants = require("./constants");
var _default = exports["default"] = {
  openMainNav: function openMainNav() {
    return {
      type: _constants.ON_NAV_OPEN,
      isNavOpen: true
    };
  },
  closeMainNav: function closeMainNav() {
    return {
      type: _constants.ON_NAV_CLOSE,
      isNavOpen: false
    };
  }
};