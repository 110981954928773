import React from "react";

import "./index.css";

import Notification from "./Notification";

export default ({ notifications, onSelect, status }) => {
  if (status === 408) return <div>Notifications are unreachable</div>;
  if (notifications === undefined) return <div>Loading ...</div>;
  if (notifications.length === 0) return <div>No unread notifications</div>;

  return (
    <ul className="divided-list font-xs">
      {notifications.map(item => (
        <li
          test-data={`notification-${item.id}`}
          className="notification-item"
          key={Number(item.id)}
        >
          <Notification notification={item} onSelect={onSelect} />
        </li>
      ))}
    </ul>
  );
};
