import { connect } from "react-redux";

import { getItems, genSaveItemsFunct } from "lib/stateHelpers";

const mapStateToProps = state => ({ complaints: getItems(state, "complaints") });

const mapDispatchToProps = dispatch => ({
  saveComplaints: genSaveItemsFunct(dispatch, "complaints")
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
