"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformKeys = exports["default"] = void 0;
var _isObject = _interopRequireDefault(require("lodash/isObject"));
var _isArray = _interopRequireDefault(require("lodash/isArray"));
var _map = _interopRequireDefault(require("lodash/map"));
var _transform = _interopRequireDefault(require("lodash/transform"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var _transformKeys = exports.transformKeys = function transformKeys(source, modifier) {
  if ((0, _isArray["default"])(source)) {
    return (0, _map["default"])(source, function (item) {
      return _transformKeys(item, modifier);
    });
  } else if ((0, _isObject["default"])(source)) {
    return (0, _transform["default"])(source, function (result, value, key) {
      if (value instanceof File || value instanceof FileList) {
        result[modifier(key)] = value;
      } else {
        result[modifier(key)] = _transformKeys(value, modifier);
      }
      return result;
    }, {});
  } else {
    return source;
  }
};
var _default = exports["default"] = _transformKeys;