"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var DOMBodyTag = {
  addClass: function addClass(className) {
    document.body.classList.add(className);
  },
  removeClass: function removeClass(className) {
    document.body.classList.remove(className);
  },
  addListener: function addListener(event, callback) {
    document.body.addEventListener(event, callback);
  },
  removeListener: function removeListener(event, callback) {
    document.body.removeEventListener(event, callback);
  }
};
var _default = exports["default"] = DOMBodyTag;