import { compose } from "redux";
import component from "./index.component";
import container from "./index.container";
import wrapper from "./index.wrapper";
import withCurrentUser from "lib/containers/withCurrentUser";
import "./index.css";

export default compose(
  withCurrentUser,
  container,
  wrapper
)(component);
